<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("source.listTitle") }}</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn small color="primary" @click="editItem(null)">
          {{ $t("source.create") }}
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-right">
      <span v-if="totalCount > 0" class="ml-2 body-2"
        >Showing {{ offset + 1 }}-{{ maxItem }} of {{ totalCount }} items</span
      >
    </div>
    <div>
      <v-overlay
        :absolute="true"
        :value="dataLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div v-if="!dataLoading">
        <!--    Data table     -->
        <v-data-iterator
          hide-default-footer
          disable-filtering
          disable-sort
          :items="items"
        >
          <template v-slot:header>
            <div class="px-4 py-0 pb-0">
              <v-row class="grey--text body-2">
                <v-col cols="3">
                  {{ $t("source.attribute.name") }}
                </v-col>
                <v-col cols="4">
                  {{ $t("source.attribute.url") }}
                </v-col>
                <v-col cols="2" class="text-center">
                  {{ $t("source.attribute.enabled") }}
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:default="props">
            <source-item
              v-for="(item, i) in props.items"
              :key="i"
              :item="item"
              @editInfo="editItem(item)"
              @deleteItem="confirmItem(item)"
            ></source-item>
          </template>
        </v-data-iterator>
        <div class="text-right">
          <span v-if="totalCount > 0" class="ml-2 body-2"
            >Showing {{ offset + 1 }}-{{ maxItem }} of
            {{ totalCount }} items</span
          >
        </div>
      </div>
    </div>
    <!--    Pagination    -->
    <div v-if="pageCount > 1" class="text-center pt-6">
      <v-pagination
        v-model="pagination.page"
        :length="pageCount"
        :total-visible="9"
      ></v-pagination>
    </div>
    <v-dialog v-model="editDialog" max-width="450px">
      <source-form
        v-if="editDialog"
        :service="selectedItem"
        @close="closeEditDialog"
        @submitted="
          loadItems();
          closeEditDialog();
        "
      ></source-form>
    </v-dialog>
    <v-dialog v-if="selectedItem" v-model="deleteDialog" max-width="450px">
      <v-card>
        <v-card-title
          class="headline primary lighten-1 white--text justify-space-between"
        >
          Delete confirmation
          <v-btn icon color="white" @click="deleteDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          Are you sure you want to delete {{ selectedItem.name }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="deleteDialog = false">Close</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItem(selectedItem)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { FETCH_SERVICES, DELETE_SERVICE } from "@/store/actions/service";

import SourceItem from "@/views/source-management/list/SourceItem";
import SourceForm from "@/views/source-management/item/SourceForm";

export default {
  name: "SourceList",
  components: { SourceItem, SourceForm },
  data() {
    return {
      selectedItem: null,
      editDialog: false,
      deleteDialog: false,
      dataLoading: true,
    };
  },
  computed: {
    ...mapState("service", ["pagination"]),
    ...mapGetters("service", [
      "offset",
      "itemsPerPage",
      "totalCount",
      "items",
      "pageCount",
    ]),
    maxItem: function () {
      return Math.min(this.totalCount, this.offset + this.itemsPerPage);
    },
  },
  watch: {
    pagination: {
      handler: function () {
        this.loadItems();
      },
      deep: true,
    },
  },
  methods: {
    loadItems: function () {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + FETCH_SERVICES)
        .then(
          () => {
            this.dataLoading = false;
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
    editItem: function (item) {
      this.selectedItem = item;
      this.editDialog = true;
    },
    closeEditDialog: function () {
      this.selectedItem = null;
      this.editDialog = false;
    },
    confirmItem: function (item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },
    deleteItem: function (item) {
      this.dataLoading = true;
      this.$store
        .dispatch("service/" + DELETE_SERVICE, item)
        .then(
          () => {
            this.dataLoading = false;
            this.selectedItem = null;
            this.deleteDialog = false;
            this.loadItems();
          },
          (err) => {
            console.log("Err", err);
          }
        )
        .catch(() => {});
    },
  },
  mounted() {
    this.loadItems();
  },
};
</script>
