<template>
  <v-form @submit.prevent="updateItem">
    <v-card>
      <v-card-title
        class="headline primary lighten-1 white--text justify-space-between"
      >
        <span v-if="service">Edit service</span>
        <span v-if="!service">Create service</span>

        <v-btn icon color="white" @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-overlay
          :absolute="true"
          :value="dataLoading"
          justify-center
          align-center
          opacity="0.15"
        >
          <v-progress-circular size="64" indeterminate></v-progress-circular>
        </v-overlay>
        <v-row>
          <v-col>
            <v-text-field
              name="name"
              :label="attributeLabels.title"
              :error-messages="serviceErrors.title"
              v-model="serviceForm.title"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              name="scope"
              :label="attributeLabels.scope"
              :error-messages="serviceErrors.scope"
              v-model="serviceForm.scope"
              type="text"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          name="url"
          :label="attributeLabels.url"
          :error-messages="serviceErrors.url"
          v-model="serviceForm.url"
          type="text"
        ></v-text-field>
        <v-text-field
          name="authorization"
          :label="attributeLabels.authorization"
          :error-messages="serviceErrors.settings.authorization"
          v-model="serviceForm.settings.authorization"
          type="text"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-text-field
              name="orderNum"
              :label="attributeLabels.orderNum"
              :error-messages="serviceErrors.settings.orderNum"
              v-model="serviceForm.orderNum"
              type="text"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-checkbox
              name="enabled"
              :label="attributeLabels.enabled"
              :error-messages="serviceErrors.enabled"
              v-model="serviceForm.enabled"
              true-value="1"
              false-value="0"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-checkbox
          name="enabled"
          :label="attributeLabels.hasAccounts"
          v-model="serviceForm.settings.hasAccounts"
          true-value="1"
          false-value="0"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="$emit('close')">Close</v-btn>
        <v-btn type="submit" color="blue darken-1" text> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import _ from "lodash";
import validator from "@/mixin/validator";
import { maxLength, required, integer } from "vuelidate/lib/validators";
import { PATCH_SERVICE } from "@/store/actions/service";

const defaultValues = {
  title: null,
  url: null,
  scope: null,
  orderNum: null,
  enabled: null,
  settings: {
    authorization: null,
  },
};

export default {
  name: "ServiceForm",
  mixins: [validator],
  props: {
    service: Object,
  },
  data: function () {
    return {
      dataLoading: false,
      serviceForm: this.service
        ? {
            title: this.service.title,
            url: this.service.url,
            scope: this.service.scope,
            orderNum: this.service.orderNum,
            enabled: this.service.enabled ? "1" : "0",
            settings: {
              authorization: this.service.settings.authorization,
              hasAccounts: this.service.settings.hasAccounts,
            },
          }
        : _.merge({}, defaultValues),
      birthdayModal: false,
      attributeLabels: {
        title: this.$t("source.attribute.name"),
        url: this.$t("source.attribute.url"),
        scope: this.$t("source.attribute.scope"),
        orderNum: this.$t("source.attribute.orderNum"),
        enabled: this.$t("source.attribute.enabled"),
        authorization: this.$t("source.attribute.authorization"),
        hasAccounts: this.$t("source.attribute.hasAccounts"),
      },
    };
  },
  computed: {
    serviceErrors: function () {
      return _.merge(
        {
          title: "",
          url: "",
          scope: "",
          orderNum: "",
          enabled: "",
          settings: {
            authorization: "",
          },
        },
        this.validator.errors.serviceForm
      );
    },
  },
  methods: {
    updateItem: function () {
      if (this.validate()) {
        this.dataLoading = true;
        const { serviceForm } = this;
        this.$store
          .dispatch("service/" + PATCH_SERVICE, {
            service: serviceForm,
            id: this.service ? this.service.id : null,
          })
          .then(
            (payload) => {
              this.dataLoading = false;
              if (payload.status) {
                this.serviceForm = defaultValues;
                this.$emit("submitted");
              } else {
                this.$notify({
                  group: "messages",
                  title: "New service",
                  text: "Has invalid scope",
                });
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
  },
  validations: {
    serviceForm: {
      title: {
        required,
        maxLength: maxLength(255),
      },
      scope: {
        required,
        maxLength: maxLength(255),
      },
      orderNum: {
        integer,
      },
      url: {
        required,
      },
      settings: {
        authorization: {
          required,
          maxLength: maxLength(255),
        },
      },
    },
  },
};
</script>
