<template>
  <v-sheet outlined class="mt-1">
    <v-card-text class="pa-2 pl-4">
      <v-row>
        <v-col cols="3">
          <v-row class="flex-nowrap">
            <v-col cols="2" sm="2" md="2" lg="2">
              <v-icon>mdi-{{ source.scope }}</v-icon>
            </v-col>
            <v-col class="text-no-wrap">
              {{ source.name }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4">
          <a v-if="source.url" target="_blank" :href="source.url">
            {{ source.url }}
          </a>
        </v-col>
        <v-col cols="2" class="text-center">
          <v-chip :color="source.enabled ? 'green' : 'red'" outlined small dark>
            {{ source.enabled ? $t("enabled") : $t("disabled") }}
          </v-chip>
        </v-col>
        <v-col cols="3" class="text-end">
          <v-btn x-small icon color="primary" @click="$emit('editInfo')">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn x-small icon color="primary" @click="$emit('deleteItem')">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-sheet>
</template>

<script>
export default {
  name: "SourceItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      source: this.item,
    };
  },
};
</script>
